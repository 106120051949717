/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import ProductTile from './ProductTile';
import { bindColors } from '../../../../../../helpers/productHelpers';
import { ReactComponent as LoadingIcon } from '../../../../../icons/sport2000-icons/loading.svg';
import OldTagManager from '../../../../domain/OldTagManager';
import TagManager from '../../../../domain/TagManager';
import GtmService from '../../../../services/gtm';
import OldGtmService from '../../../../services/oldGtm';
import ProductColorService from '../../../../services/color';
import Button from '../../../atoms/button/Button';
import LoadingFullScreen from '../../../atoms/loadingFullScreen';
import { InfiniteHitsProvided, StateResultsProvided } from 'react-instantsearch-core';
import useConsentManagement from 'anwr_sport2000/src/js/services/hook/useConsentManagement';

const LIMIT_PRODUCT_PER_LOAD = 48;

type Props = {
  objectId: string | null;
  nameCategory: string;
  blogTiles: any;
  wishlistState: object;
  refererStore: any;
  isDisplaySidebar: boolean;
  gtmCategoryName: string;
  gtmCategoryId: string;
  hasSidebarCategory: boolean;
} & InfiniteHitsProvided<any> &
  StateResultsProvided &
  WrappedComponentProps;

const Hits: React.FC<Props> = ({
  hits,
  refineNext,
  hasMore,
  searching,
  searchResults,
  gtmCategoryId,
  gtmCategoryName,
  nameCategory,
  wishlistState,
  refererStore,
  isDisplaySidebar,
  hasSidebarCategory,
  intl,
  refinePrevious,
  hasPrevious,
}) => {
  const [isActiveScroll, setIsActiveScroll] = useState(false);
  const [colors, setColors] = useState<any>({});
  const [isProductImpressTracked, setIsProductImpressTracked] = useState(false);
  const sentinel = useRef<HTMLDivElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  const [showLoading, setShowLoading] = useState(false);

  const { isGtmAccepted } = useConsentManagement();

  const handleActiveScroll = () => {
    if (sentinel.current) {
      observer.current = new IntersectionObserver(onSentinelIntersection);
      observer.current.observe(sentinel.current);
    }
    setIsActiveScroll(true);
    refineNext();
  };

  const onSentinelIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });
  };

  const handleCount = () => {
    if (!searchResults) return 0;
    return Math.min((searchResults.page + 1) * LIMIT_PRODUCT_PER_LOAD, searchResults.nbHits);
  };

  const fetchColors = async (modelKeys: string[]) => {
    const responseColors = await ProductColorService.getColors(modelKeys);
    setColors((prevColors: any) => {
      const updatedColors = { ...prevColors };
      responseColors?.forEach((hitColor: any) => {
        const hitColors = updatedColors[hitColor.root_model_key] || [];
        if (!hitColors.some((hit: any) => hit.product_id === hitColor.product_id)) {
          hitColors.push(hitColor);
        }
        updatedColors[hitColor.root_model_key] = hitColors;
      });
      return updatedColors;
    });
  };

  useEffect(() => {
    const modelKeys: string[] = [];
    const updatedColors = { ...colors };

    hits.forEach((hit) => {
      if (!updatedColors[hit.root_model_key]) {
        updatedColors[hit.root_model_key] = [hit];
        modelKeys.push(hit.root_model_key);
      }
    });

    if (modelKeys.length > 0) {
      setColors(updatedColors);
      fetchColors(modelKeys);
    }
    if (!hits?.length) {
      return
    }

    // We need to disable auto scroll restoration
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    // Scroll to previous click (from PDP to PLP)
    const selector = sessionStorage.getItem('clickedElement');
    const visitedPDP = sessionStorage.getItem('visitedPDP');
    sessionStorage.removeItem('clickedElement');
    sessionStorage.removeItem('visitedPDP');
    if (!selector || !visitedPDP) {
      return;
    }

    const article = document.querySelector(selector);
    if (article) {
      const userAgent = navigator.userAgent;
      const scrollOptions: ScrollIntoViewOptions = /Safari/.test(userAgent) && !/Chrome|Chromium/.test(userAgent)
        ? { behavior: 'instant', block: 'end' }
        : { behavior: 'smooth', block: 'center' };
      article.scrollIntoView(scrollOptions);
    }

  }, [hits, hits.length]);

  useEffect(() => {
    if (isGtmAccepted && hits?.length > 0 && !isProductImpressTracked) {
      const gtmService = new GtmService(hits);
      const productListGTM = gtmService.createProductListGTM(gtmCategoryName, gtmCategoryId);
      TagManager.productImpressions(productListGTM, gtmCategoryName, gtmCategoryId);
      TagManager.hitViewdImpressions();

      const oldGtmService = new OldGtmService(hits);
      const oldProductListGTM = oldGtmService.createProductListGTM(`Page: ${gtmCategoryName}`);
      OldTagManager.productImpressions(oldProductListGTM);
      OldTagManager.hitViewdImpressions();
      setIsProductImpressTracked(true);
    }
  }, [gtmCategoryName, gtmCategoryId, hits, isGtmAccepted, isProductImpressTracked]);

  useEffect(() => {
    if (sentinel.current && isActiveScroll) {
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && hasMore) {
              console.log('Sentinel intersected, loading more items...');
              refineNext();
            }
          });
        },
        {
          root: null,
          // rootMargin: '200px',
          threshold: 0.1,
        }
      );

      observer.current.observe(sentinel.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [hasMore, refineNext, isActiveScroll]);

  useEffect(() => {
    if (searching) {
      setShowLoading(true);
    } else {
      const timeout = setTimeout(() => {
        setShowLoading(false);
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [searching]);

  const hitsWithColors = bindColors({
    hits,
    colors,
    wishlist: wishlistState,
    store: refererStore,
  });

  if (!searchResults || !searchResults.nbHits) return null;

  const renderBtnPreviousLoad = () => (
    <div className={'container-previous-load'}>
      <Button
        type={'button'}
        onClick={refinePrevious}
        className={'btn btn-secondary btn-load-more'}
        ariaLabel={intl.formatMessage({ id: 'filters.previousLoad' })}
      >
        <FormattedMessage id={'filters.previousLoad'} />
      </Button>
    </div>
  );

  const renderLoadMoreText = () => {
    if (searchResults.nbHits - handleCount() < LIMIT_PRODUCT_PER_LOAD) {
      const productLeft = searchResults.nbHits - handleCount();
      return <FormattedMessage id={'filters.loadProductLeft'} values={{ num: productLeft }} />;
    }
    return <FormattedMessage id={'filters.loadMore.products'} values={{ num: LIMIT_PRODUCT_PER_LOAD }} />;
  };

  return (
    <div className="product-list--wrapper product-list-algolia">
      <LoadingFullScreen searching={showLoading} />
      <div className="product-list--component">
        {hasPrevious && renderBtnPreviousLoad()}

        <div
          className={classnames('product-teaser-list', {
            'xl:grid-cols-5': !isDisplaySidebar,
          })}
        >
          {hitsWithColors.map((hit, index) => (
            <ProductTile
              key={hit.product_id}
              position={index + 1}
              product={hit}
              nameCategory={nameCategory}
              colors={hit.colors}
              refererStore={refererStore}
              hasSidebarCategory={hasSidebarCategory}
              isDisplaySidebar={isDisplaySidebar}
            />
          ))}
          <div ref={sentinel} className="ais-InfiniteHits-sentinel" />
        </div>
        <div className="product-list--load-more">
          <div className="product-status">
            <FormattedMessage
              id="filters.productStatus"
              values={{ count: handleCount(), total: searchResults.nbHits }}
            />
          </div>
          <div className="progress-bar">
            <span style={{ width: `${(handleCount() / searchResults.nbHits) * 100}%` }} />
          </div>
          {hasMore && (
            <div className="flex justify-center">
              {isActiveScroll ? (
                searching ? (
                  <LoadingIcon width={30} height={30} />
                ) : null
              ) : (
                <>
                  <Button
                    type="button"
                    onClick={refineNext}
                    className="btn btn-secondary btn-load-more"
                    ariaLabel={renderLoadMoreText()}
                  >
                    {searching ? <LoadingIcon width={30} height={30} /> : renderLoadMoreText()}
                  </Button>
                  <Button
                    type="button"
                    onClick={handleActiveScroll}
                    className="btn btn-default btn-load-more"
                    ariaLabel={intl.formatMessage({ id: 'filters.loadAll' })}
                  >
                    {searching ? <LoadingIcon width={30} height={30} /> : <FormattedMessage id="filters.loadAll" />}
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((state: any) => ({
  objectId: state['product-list-algolia'].objectId,
}))(connectInfiniteHits(connectStateResults(injectIntl(Hits))));
