import qs from 'qs'
import { SearchState } from 'react-instantsearch-core'
import ProductListService from './ProductListService'

export type QueryParams = {
  query?: string
  page?: number
  sortBy?: string
  range?: {
    [key: string]: {
      min?: number
      max?: number
    }
  }
  categoryOption?: any
  categoryFilter?: any
  categoryKey?: any
  categoryName?: any
  filters?: {
    [key: string]: string[];
  };
  categorySidebar?: any
}

interface ParsedQueryParams {
  path?: string;
  locale?: string;
  [key: string]: any;
}

interface RangeValues {
  min?: number;
  max?: number;
}

export default class RoutingService {
  public static createURL(state: SearchState, brandInFilters = true) {
    if (state.page) {
      ProductListService.addRefinedPage(state.page)
    }

    const isDefaultRoute = !state.query
      && state.page === 1
      && (!state.sortBy || Object.keys(state.sortBy).length === 0)
      && (!state.refinementList || Object.keys(state.refinementList).length === 0)
      && (!state.range || Object.keys(state.range).length === 0)

    if (isDefaultRoute) {
      return ''
    }

    const queryParameters: QueryParams = {}

    if (state.query) {
      queryParameters.query = encodeURIComponent(state.query)
    }

    if (state.page !== 1) {
      queryParameters.page = state.page
    }

    if (state.sortBy) {
      queryParameters.sortBy = state.sortBy
    }

    if (state.range && Object.keys(state.range).length > 0) {
      const rangeList = state.range

      Object.keys(rangeList).forEach((key) => {
        if (rangeList?.hasOwnProperty(key)
          && !(rangeList[key].max || rangeList[key].min)
        ) {
          delete rangeList[key]
        }
      })

      if (Object.keys(rangeList).length > 0) {
        queryParameters.range = rangeList
      }
    }

    if (state.refinementList && Object.keys(state.refinementList).length > 0) {
      const list = state.refinementList

      Object.keys(list).forEach((key) => {
        if (list?.hasOwnProperty(key) && list[key].length === 0) {
          delete list[key]
        }
      })

      if (Object.keys(list).length > 0) {
        queryParameters.filters = list
      }
    }

    const urlParameters = qs.parse(
      window.location.search.slice(1),
    )
    const {
      categoryOption, categoryFilter, categoryKey, categoryName, categorysidebar,
    } = urlParameters
    if (categoryFilter) {
      queryParameters.categoryOption = categoryOption
      queryParameters.categoryFilter = categoryFilter
      queryParameters.categoryKey = categoryKey
      queryParameters.categoryName = categoryName
    }

    if (categorysidebar) {
      queryParameters.categorySidebar = categorysidebar
    }

    const queryToGenerate = {
      ...queryParameters,
      filters: {
        ...queryParameters.filters,
        ...brandInFilters ? {} : { brand: null }
        ,
      },
    }

    const str = qs.stringify(queryToGenerate, {
      addQueryPrefix: true,
      // Netlify "normalizes" query parameters. Multiple parameters with the same name are combined into a single
      // comma separated values. For that reason we can't use 'brackets' here but have to use 'indices'
      arrayFormat: 'indices',
      skipNulls: true,
    })

    return str
  }

  public static generateBrandQueryUrl(searchState: SearchState | null) {
    const brandQuery = searchState.refinementList?.brand

    if (!brandQuery) {
      return ''
    }

    const encodedBrandQuery = brandQuery.map(brand => brand.replace(/\s+/g, '-'))
    return `_${encodedBrandQuery.join('_')}`
  }

  public static brandQueryToUrl(pathname, brandQueryUrl) {
    const pathnameSegments = pathname.split('/')
    if (pathnameSegments[pathnameSegments.length - 1].includes('_')) {
      pathnameSegments[pathnameSegments.length - 1] = brandQueryUrl
    } else {
      pathnameSegments.push(brandQueryUrl)
    }
    // Remove '/' at the end of pathname by regex
    return pathnameSegments.join('/').replace(/\/$/, '').toLocaleLowerCase()
  }

  public static searchStateToUrl(searchState: SearchState | null, brandInFilters = true) {
    return searchState ? this.createURL(searchState, brandInFilters) : ''
  }

  public static queryToSearchState(q: any) {
    const {
      query = '', page = 1, filters, sortBy, range,
    } = q

    ProductListService.addRefinedPage(parseFloat(page.toString(10)))

    const state: any = {
      query: decodeURIComponent(query.toString()),
      page,
      refinementList: filters,
      range,
    }

    if (sortBy) {
      state.sortBy = sortBy
    }

    return state
  }

  public static brandUrlToSearchState(pathname: string) {
    if (!pathname) {
      return
    }

    const slugs = pathname.split('/')
    const brandSlug = slugs.pop()

    if (brandSlug.includes('_')) {
      return brandSlug.split('_').slice(1).map(brand => brand.replace(/-/g, ' '))
    }

    return null
  }

  public static urlToSearchState(
    queryParams: ParsedQueryParams,
    searchQuery: string | null,
    initialFacets: Record<string, Record<string, any>> | null,
    isInitialLoad = true
  ): SearchState {
    // Parse query parameters into search state
    const parsedState = RoutingService.queryToSearchState({
      ...qs.parse(queryParams, { comma: true, parseArrays: true }),
      query: searchQuery,
    });
    // Extract path information
    const searchQueryPath = queryParams?.path || (queryParams?.slug ? `/${queryParams?.slug.join('/')}` : '');

    // Process brand information if available
    const brand = RoutingService.brandUrlToSearchState(searchQueryPath);
    const refinementList = { ...parsedState.refinementList };

    if (brand) {
      refinementList.brand = brand;
    }

    // Process range parameters (convert strings to numbers)
    const range = Object.fromEntries(
      Object.entries(parsedState.range ?? {}).map(([key, value]) => [
        key,
        {
          min: value['min'] ? parseFloat(value['min']) : undefined,
          max: value['max'] ? parseFloat(value['max']) : undefined,
        },
      ])
    );

    // Normalize refinement list with correct property names
    if (isInitialLoad && initialFacets) {
      Object.keys(refinementList).forEach((key) => {
        if (initialFacets[key]) {
          const propertyNames = Object.keys(initialFacets[key]);
          refinementList[key] = propertyNames.filter(
            (propertyName) => refinementList[key].indexOf(propertyName) !== -1
          );
        }
      });
    }

    return {
      ...parsedState,
      refinementList,
      range: Object.keys(range).length > 0 ? range : parsedState.range,
    };
  }
}
