import { DISCOUNT_PERCENT_THRESHOLD } from 'anwr_sport2000/src/js/services/price';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { connectRange } from 'react-instantsearch-core';
import { Context } from 'anwr_sport2000/src/js/types/frontastic';

type Props = {
  context: Context
  refine: (value: { min?: number; max?: number }) => void;
  currentRefinement: { min?: number; max?: number };
  attribute: string;
};

const DISCOUNT_PERCENT_LOWER_BOUND = 15.01;

const SalePriceCheckbox: React.FC<Props> = ({ refine, currentRefinement, context }) => {
  const router = useRouter();
  const { asPath } = router;
  // Check if the filter is applied (min > 15)
  const [isSale, setIsSale] = useState<boolean>(
    currentRefinement.min !== undefined && currentRefinement.min > DISCOUNT_PERCENT_THRESHOLD
  );

  // Update checkbox state when currentRefinement changes (URL or other components may change it)
  useEffect(() => {
    const shouldBeChecked = currentRefinement.min !== undefined && currentRefinement.min > DISCOUNT_PERCENT_THRESHOLD;
    if (shouldBeChecked !== isSale) {
      setIsSale(shouldBeChecked);
    }
  }, [currentRefinement, isSale]);

  // Toggle filter on checkbox change
  const handleFilterPercentDiscount = () => {
    const newIsSale = !isSale;
    setIsSale(newIsSale);

    // Apply or clear the filter
    if (newIsSale) {
      refine({ min: DISCOUNT_PERCENT_LOWER_BOUND });
    } else {
      refine({ min: undefined, max: undefined });
    }
  };

  // Check if the URL contains "/:category/sale"
  const isInShopSale = asPath.includes('/sale');

  return (
    <div className="product-filter-price--sale w-full flex justify-center items-center checkbox-radio--wrapper">
      <input
        id="is-sale-checkbox"
        className="self-center"
        type="checkbox"
        checked={isSale}
        onChange={handleFilterPercentDiscount}
        disabled={isInShopSale}
      />

      <label
        htmlFor="is-sale-checkbox"
      >
        {context?.projectConfiguration?.['algoliaFilterSaleLabel']}
      </label>
    </div>
  );
};

export default connectRange(SalePriceCheckbox);