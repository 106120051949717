import React from 'react'
import { FilterTypeEnum } from '../FilterTypeEnum'
import Select from './Select'
import FilterPriceRange from './Price'
import { Context } from '../../../../../types/frontastic'
import FilterService from '../../../../../services/algolia/FilterService'
// import Flags from './Flags'
import Color from './Color'

export type Props = {
    attribute: string
    algoliaIndexName?: string
    limit: number
    index?: number
    context?: Context
    isActiveFilter?: boolean
}

const FilterTypes: React.FC<Props> = (props: Props) => {
    const { attribute, isActiveFilter, algoliaIndexName } = props

    switch (FilterService.mapFilterAttribute(attribute)) {
        case FilterTypeEnum.DEFAULT:
            return <Select {...props} />
        case FilterTypeEnum.PRICE:
            return <FilterPriceRange {...props} />
        case FilterTypeEnum.COLOR:
            return <Color {...props} />
        case FilterTypeEnum.LOGO:
            return <Select {...props} isActiveFilter={isActiveFilter} algoliaIndexName={algoliaIndexName} isLogo />
        default:
            return null
    }
}

export default FilterTypes
