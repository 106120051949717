import React, { memo } from 'react';
import { CSSTransition } from 'react-transition-group';

const LoadingFullScreen = ({ searching }) => {
  return (
    <CSSTransition
      in={searching}
      timeout={300}
      classNames="fade"
      unmountOnExit
    >
      <div className="loading-screen">
        <div className="animation" />
      </div>
    </CSSTransition>
  );
};

export default memo(LoadingFullScreen);
