import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connectCurrentRefinements, connectDynamicWidgets, connectStateResults } from 'react-instantsearch-dom'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import ProductListService from 'anwr_sport2000/src/js/services/algolia/ProductListService'
import BaseDotted from '../../../../atoms/dotted/BaseDotted'
import { DottedFadebarVariantA5mm, DottedFadebarVariantB5mm } from '../../../../atoms/dotted'
import CategoryLayer from '../../../ProductListing/CategoryLayer'
import FilterTypes from '../FilterTypes'
import { ReactComponent as FilterIcon } from '../../../../../../icons/sport2000-icons/filter.svg'
import Refinements from '../Refinements'
import { setNameCategory, setShowMore } from '../../../../../store/actions/ProductListActions'
import Headline from '../Headline'
import { ReactComponent as CloseIcon } from '../../../../../../icons/sport2000-icons/close.svg'
import { ReactComponent as LoadingIcon } from '../../../../../../icons/sport2000-icons/loading.svg'
import Button from '../../../../atoms/button/Button'
import { ReactComponent as InfoIcon } from '../../../../../../icons/sport2000-icons/info.svg'

const DesktopFilter = (props) => {
  const {
    attributesToRender,
    context,
    intl,
    node,
    headline,
    searchResults,
    refine,
    items,
    searching,
    algoliaIndexName,
    backgroundColor,
    category,
    refinementListBrand,
    categoryPageHeadline,
    searchState
  } = props

  const dispatcher = useDispatch()
  const {
    showMore, nameCategory,
  } = useSelector((state) => state['product-list-algolia'])
  const [isOpen, setIsOpen] = useState(false)
  const [categoryNameInSearchPage, setCategoryNameInSearchPage] = useState('')
  const isMobile = useDeviceType() === 'mobile'

  const filterArrayAttributes = useMemo(() => {
    const filterFacetName = attributesToRender.filter((attribute) => searchResults?.disjunctiveFacets?.some((facet) => facet.name === attribute))
    const filterDuplicateAttribute = filterFacetName.filter((item, pos) => filterFacetName.indexOf(item) === pos)
    return filterDuplicateAttribute
  })

  const getParameterURLInSearchPage = () => {
    if (node?.nodeType === 'search') {
      const originParameter = new URLSearchParams(window.location.search)
      const getCategoryFilter = originParameter.get('categoryFilter')

      if (getCategoryFilter === '') {
        setCategoryNameInSearchPage(originParameter.get('categoryName'))
      }
    }
  }

  useEffect(() => {
    dispatcher(setShowMore({
      showMore: filterArrayAttributes.length > 6,
    }))

    dispatcher(setNameCategory({
      nameCategory,
    }))
    getParameterURLInSearchPage()
  }, [])

  const styleBackground = {}

  if (backgroundColor && !isOpen) {
    styleBackground.backgroundColor = backgroundColor
  }

  const renderDottedBarForPanels = () => (
    <div className={'teaser--dotted-bar hidden lg:block'}>
      <BaseDotted
        dottedSVG={<DottedFadebarVariantB5mm fill={backgroundColor} />}
        height={14}
        isBottom
        isRotate
      />
      <BaseDotted
        dottedSVG={<DottedFadebarVariantA5mm fill={backgroundColor} />}
        height={14}
        isBottom
        isRotate
        from={'right'}
      />
    </div>
  )
  const renderDottedBarForHeadline = () => (
    <div className={'teaser--dotted-bar hidden lg:block'}>
      <BaseDotted
        dottedSVG={<DottedFadebarVariantB5mm fill={backgroundColor} />}
        height={14}
        isTop
        isRotate
      />
      <BaseDotted
        dottedSVG={<DottedFadebarVariantB5mm fill={backgroundColor} />}
        height={14}
        isTop
        from={'right'}
      />
    </div>
  )

  const handleShowMoreFacets = () => {
    dispatcher(setShowMore({
      showMore: !showMore,
    }))
  }

  const getCategoryName = () => {
    const categoryBrandSeo = category?.projectSpecificData?.brandSeo

    if (categoryBrandSeo) {
      return categoryBrandSeo.h1
    }
    // Check if category has children and handle accordingly
    if (category) {
      let headlineResult = ""
      if (searchState?.range?.percentageDiscount?.min) {
        headlineResult = "Sale: "
      }
      if (refinementListBrand?.length === 1) {
        const headlineWithBrand = ProductListService.getTitleWhenFilterByBrand(categoryPageHeadline, refinementListBrand[0])
        return `${headlineResult}${headlineWithBrand}`
      }

      return `${headlineResult}${categoryPageHeadline}`
    }

    // Handle the case when there are no children
    if (refinementListBrand?.length === 1) {
      return ProductListService.getTitleWhenFilterByBrand(categoryPageHeadline, refinementListBrand[0])
    }

    return categoryPageHeadline
  }

  const renderHeadlineOnMobile = () => {
    if (node.nodeType === 'search') {
      return (
        <Headline
          node={node}
          nameCategory={getCategoryName()}
          headline={headline}
          context={context}
        />
      )
    }

    if (node.nodeType === 'category' || node.nodeType === 'sale-category') {
      return (
        <CategoryLayer
          name={getCategoryName()}
          headline={headline}
          styleBackground={styleBackground}
          backgroundColor={backgroundColor}
        />
      )
    }

    return (
      <Headline
        node={node}
        headline={headline}
        context={context}
      />
    )
  }

  if (!searchResults?.nbHits) {
    return null
  }

  return (
    <>
      <div className={'filter-algolia'} style={styleBackground}>
        <button
          className={'btn btn-secondary icon--right filter-algolia--btn-open-filter flex lg:hidden'}
          type={'button'}
          aria-label={intl.formatMessage({ id: 'filters.all' })}
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <FormattedMessage id={'filters.all'} />
          <FilterIcon width={20} height={20} />
        </button>
        {isMobile && (<div className={'filter-algolia--wrapper is--title-mobile block lg:hidden'}>
          {renderHeadlineOnMobile()}
        </div>)}

        {renderDottedBarForHeadline()}

        {!isMobile && (
          <div className={'filter-algolia--wrapper'}>
            <Headline
              nameCategory={getCategoryName()}
              node={node}
              headline={headline}
              customClassName={'filter-category-headline'}
              context={context}
            />
          </div>
        )}
      </div>

      <div className={'filter-algolia--container-all-panels'} style={styleBackground}>
        {renderDottedBarForPanels()}
        <div className={classnames('filter-algolia--wrapper-all-panels', {
          'is--open': isOpen,
        })}
        >
          <div className={'filter-algolia--header-mobile flex lg:hidden'}>
            <h6><FormattedMessage id={'filters.filters'} /></h6>
            <CloseIcon width={20} height={20} onClick={() => setIsOpen(false)} />
          </div>
          <div className={'filter-algolia--panel'}>
            <Refinements customClassName={'flex lg:hidden border-b border-solid border-blacksport-40'} />

            {filterArrayAttributes.map((attribute, index) => (
              <FilterTypes
                attribute={attribute}
                key={attribute}
                limit={Infinity}
                context={context}
                index={index}
                algoliaIndexName={algoliaIndexName}
              />
            ))}
            {filterArrayAttributes.length > 6 && !isMobile && (
              <button
                className={'filter-algolia--btn-more-panel btn btn-secondary icon--right'}
                type={'button'}
                aria-label={intl.formatMessage({ id: 'filters.all' })}
                onClick={handleShowMoreFacets}
              >
                {showMore ? <FormattedMessage id={'filters.all'} />
                  : <FormattedMessage id={'filters.less'} />}
                <FilterIcon width={20} height={20} />
              </button>
            )}
          </div>

          <div className={'filter-algolia--footer-mobile flex flex-col lg:hidden'}>
            {searching ? (
              <p className={'flex justify-center'}>
                <LoadingIcon width={20} height={20} />
              </p>
            ) : (
              <p className={'text-center'}>
                {`${searchResults.nbHits} `}
                <FormattedMessage id={'filters.total'} />
              </p>
            )}
            <div className={'flex flex-grow'}>
              <Button
                className={'btn btn-secondary filter-algolia--footer-mobile-reset'}
                type={'button'}
                ariaLabel={intl.formatMessage({ id: 'filters.filters' })}
                onClick={() => refine(items)}
              >
                <FormattedMessage id={'filters.resetAll'} />
              </Button>
              <Button
                className={'btn btn-default filter-algolia--footer-mobile-button'}
                type={'button'}
                ariaLabel={intl.formatMessage({ id: 'filters.filters' })}
                onClick={() => setIsOpen(false)}
              >
                <FormattedMessage id={'filters.filters'} />
              </Button>
            </div>
          </div>

          <Refinements customClassName={'hidden lg:flex'} />
        </div>
      </div>

      <>
        {categoryNameInSearchPage && (
          <div className={'search--empty pb-4 pt-0'}>
            <div className={'flex search--empty-product'}>
              <div className={'search--empty-icon my-auto'}>
                <InfoIcon width={20} height={20} />
              </div>
              <p className={'ml-3'}>
                <FormattedMessage id={'search.searchHits.emptyCategory'} />
                <span className={'font-bold'}>
                  {categoryNameInSearchPage}
                </span>
                <FormattedMessage id={'search.searchHits.emptyCategoryLast'} />
              </p>
            </div>
          </div>
        )}
      </>
    </>
  )
}

DesktopFilter.propTypes = {
  attributesToRender: PropTypes.any.isRequired,
  context: PropTypes.any,
  intl: PropTypes.any,
  node: PropTypes.any,
  searchResults: PropTypes.any,
  refine: PropTypes.any,
  items: PropTypes.any,
  headline: PropTypes.string,
  searching: PropTypes.bool,
  backgroundColor: PropTypes.string,
  initNameCategory: PropTypes.string,
  algoliaIndexName: PropTypes.string,
  refinementListBrand: PropTypes.any,
  categoryPageHeadline: PropTypes.any,
}

export default connectDynamicWidgets(connectCurrentRefinements(connectStateResults(injectIntl(DesktopFilter))))
