import React, { useState } from 'react'
import classnames from 'classnames'
import { ReactComponent as ArrowRightIcon } from '../../../../../icons/sport2000-icons/arrow-right.svg'
import { ReactComponent as ArrowDownIcon } from '../../../../../icons/sport2000-icons/arrow-down.svg'
import CategoryService from "anwr_sport2000/src/js/services/category"
import Link from 'next/link'

type Props = {
    item: any,
    facetCategory: any,
    node: any,
    displaySidebarByResult: boolean
    isCategorySidebarActive?: boolean
}

const MenuItem: React.FC<Props> = ({
    item, facetCategory, node, displaySidebarByResult, isCategorySidebarActive = false
}) => {
    if (!item) {
        return null
    }

    const [isActive, setIsActive] = useState(isCategorySidebarActive)

    const getProductCount = (item) => {
        if (facetCategory) {
            return facetCategory[item.id] || 0
        }

        return item.productCount
    }

    const getChildrenProductCount = (item) => {
        if (!item.children || item.children.length == 0) {
            return 0
        }

        let totalProductCount = 0
        item.children.forEach((subItem) => {
            totalProductCount += getProductCount(subItem)
        })
        return totalProductCount
    }

    if (!getProductCount(item)) {
        return null
    }
    if (node.nodeType == 'sale-category' && getProductCount(item) < 10) {
        return null
    }

    const getCategoryUrl = (item) => {
        if (displaySidebarByResult || node.nodeType == 'store') {
            const currentParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '')
            currentParams.set('categorySidebar', item.id)
            return getProductCount(item) > 0 ? window.location.pathname + '?' + currentParams.toString() : '#'
        }

        return getProductCount(item) > 0 ? item.url : '#'
    }

    return (
        <div className={'menu-algolia--item-wrapper'}>
            <div className={classnames('menu-algolia--item', {
                active: isActive,
                'bg-transparent': !isCategorySidebarActive,
            })}
            >
                <Link
                    href={getCategoryUrl(item)}
                    className={classnames({
                        active: isActive,
                        'text-blacksport-50 pointer-events-none': getProductCount(item) < 1,
                    })}
                >
                    <label className={'cursor-pointer'}>
                        {item.name}
                    </label>
                </Link>
                {getChildrenProductCount(item) > 0 ? (
                    <div
                        className={'is--arrow'}
                        onClick={() => setIsActive(!isActive)}
                    >
                        {isActive ? <ArrowDownIcon />
                            : <ArrowRightIcon />}
                    </div>
                ) : (
                    <span>
                        (
                        {getProductCount(item)}
                        )
                    </span>
                )}
            </div>
            {item.children && item.children.length > 0 && (
                <div className={classnames('menu-algolia--item-children', {
                    hidden: !isActive
                })}
                >
                    {item.children.map((subItem) => (
                        <MenuItem
                            key={subItem.id}
                            item={subItem}
                            facetCategory={facetCategory}
                            node={node}
                            displaySidebarByResult={displaySidebarByResult}
                            isCategorySidebarActive={CategoryService.isCategoryActive(subItem)}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default MenuItem
