import React, { useEffect, useMemo, useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import getTranslation from '@frontastic/catwalk/src/js/getTranslation'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import ProductListService from 'anwr_sport2000/src/js/services/algolia/ProductListService'
import StoreService from 'anwr_sport2000/src/js/services/algolia/StoreService'
import SEOBlock from 'components/commercetools-ui/seo-block'
import { StringHelpers } from 'helpers/stringHelpers'
import _isEqual from 'lodash/isEqual'
import _debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { useDispatch, useSelector } from 'react-redux'
import CountProduct from '../../../patterns/organisms/Algolia/Count'
import EmptyResult from '../../../patterns/organisms/Algolia/EmptySearch'
import DesktopFilter from '../../../patterns/organisms/Algolia/Filter/Desktop'
import Hits from '../../../patterns/organisms/Algolia/Hits'
import SidebarMenu from '../../../patterns/organisms/Algolia/SidebarMenu'
import SlideMenu from '../../../patterns/organisms/Algolia/SlideMenu'
import Sort from '../../../patterns/organisms/Algolia/Sort'
import ClientFactory from '../../../services/algolia/ClientFactory'
import RoutingService from '../../../services/algolia/RoutingService'
import BlogService from '../../../services/blog'
import useVirtualPageView from '../../../services/hook/useVirtualPageView'
import { setCategorySeo, setCategoryUrl, setNameCategory } from '../../../store/actions/ProductListActions'
import Breadcrumb from '../../breadcrumb/tastic'


function AlgoliaProductListTastic({
  node, context, data
}) {
  const wishlistState = useSelector((state) => state.wishlist?.wishlist?.data?.lineItems || [])
  const { categoryUrl } = useSelector((state) => state['product-list-algolia'])
  const activeFilter = useSelector((state) => state['product-list-algolia'].openFilter)
  const isMobile = useDeviceType() === 'mobile'
  const isTablet = useDeviceType() === 'tablet'
  const isShowSlider = isMobile || isTablet

  const { indexName } = context?.project?.configuration?.algolia?.languages?.[context?.locale]
  const resultsState = data?.stream
  const refererStoreFromConfig = useMemo(() => data?.stream?.store && StoreService.formatStoreForCookie(data?.stream?.store), [data?.stream?.store])
  const sidebarCategories = resultsState?.sidebarCategories ?? []
  const initFacets = resultsState?.rawResults?.[0]?.facets

  const category = node.configuration.entity || {}
  const extendCurrentCategory = resultsState?.extendCurrentCategory || {}
  if (category.projectSpecificData) {
    category.projectSpecificData.children = extendCurrentCategory?.children
    category.projectSpecificData.parent = extendCurrentCategory?.parent
  }

  const { algoliaIndexName } = context?.projectConfiguration || {}

  const isCategoryPage = node.nodeType === 'category'
  const gtmCategoryName = isCategoryPage ? node?.configuration?.entity?.name : node.name
  const gtmCategoryId = isCategoryPage ? node?.configuration?.entity?.categoryId : node.nodeType

  let categoryPageHeadline = node.name
  if (data?.headline) {
    categoryPageHeadline = data?.headline
  } else if (category && category.projectSpecificData) {
    categoryPageHeadline = category.projectSpecificData.categoryHeadline ? category.projectSpecificData.categoryHeadline : category.name
  }
  const categorySeoTitle = getTranslation(
    node.configuration.seoTitle,
    context?.locale,
    context?.project.defaultLanguage,
  ).text
  const categorySeoDescription = getTranslation(
    node.configuration.seoDescription,
    context?.locale,
    context?.project.defaultLanguage,
  ).text

  const { setDependencies } = useVirtualPageView()
  const dispatcher = useDispatch()
  const router = useRouter()
  const searchQuery = router.query.query
  const initQuery = data?.stream?.configureProps?.filter((configProp) => configProp.prop === 'query')[0]?.value || searchQuery || ''

  const [count, setCount] = useState(0)
  const [searchState, setSearchState] = useState(RoutingService.urlToSearchState(router.query, initQuery, initFacets, count))
  const [refinementListBrand, setRefinementListBrand] = useState([])

  const searchConfigurations = ClientFactory.generateConfigure(data?.stream?.configureProps)
  const isDisplayFilter = data?.displayFilters ?? true
  const isDisplaySidebar = data?.displaySidebar ?? true
  const isDisplaySorting = data?.displaySorting ?? true
  const hasSidebarCategory = sidebarCategories?.length > 0
  const sortOptions = context?.projectConfiguration?.algoliaSortOptions ?? []
  const algoliaClient = ClientFactory.getSearchClient(context)

  const [blogTiles, setBlogTiles] = useState([])
  const [facetCategory, setFacetCategory] = useState(null)
  const initialFilter = data?.stream.initialFilter ?? ''
  const displaySidebarByResult = data?.displaySidebarByResult ?? false
  const selectedCategorySidebar = data?.selectedCategorySidebar ?? ''
  const brandCategorySeo = category?.projectSpecificData?.brandSeo

  const onSearchStateChange = _debounce((updatedSearchState, isUpdateUrl = true) => {
    const queryUrl = new URLSearchParams(RoutingService.searchStateToUrl(updatedSearchState, !isCategoryPage))
    const brandQueryUrl = isCategoryPage ? RoutingService.generateBrandQueryUrl(updatedSearchState) : ''
    const pathname = RoutingService.brandQueryToUrl(window.location.pathname, brandQueryUrl)

    let url = `${pathname}?${queryUrl}`

    // Merge query params with current URL, for the cases when user on search page with category filter
    if (node.nodeType === 'search') {
      const currentParams = new URLSearchParams(window.location.search)
      queryUrl.forEach((value, key) => currentParams.set(key, value))
      const finalQueryUrl = currentParams.toString()
      url = `${pathname}?${finalQueryUrl}`
    }

    if (categoryUrl) {
      url = `${categoryUrl}${queryUrl}`
    }

    if (updatedSearchState.refinementList?.brand?.length > 0 || searchState.refinementList?.brand?.length > 0) {
      router.replace(url, undefined, { shallow: _isEqual(updatedSearchState.refinementList.brand, searchState.refinementList.brand), scroll: false })
    } else if (isUpdateUrl) {
      router.push(url, undefined, { shallow: true, scroll: false })
    }

    setDependencies(url)

    dispatcher(setCategoryUrl({
      categoryUrl: null,
    }))

    // if (!isEmpty(refinementList)) {
    ClientFactory.queryInformationCategory({
      searchState: updatedSearchState,
      context,
      initialFilter,
      displaySidebarByResult
    }).then((res) => {
      setFacetCategory(res.results[0]?.facets?.categories)
    })

    setSearchState(updatedSearchState)
  }, 500)

  // only apply for store page when filter category

  const renderSeoBlock = () => {
    let seoH2
    let seoText
    if (brandCategorySeo) {
      seoH2 = brandCategorySeo.h2
      seoText = brandCategorySeo.text
    }
    return (
      <div className='mt-5 md:mt-10'>
        <SEOBlock
          seoH2={seoH2}
          seoText={seoText}
        />
      </div>
    )
  }

  useEffect(() => {
    const handleScrollAfterFilterCategory = () => {
      if (node.nodeType !== 'store' && !displaySidebarByResult) {
        return
      }
      if (window.location.search.indexOf('categorySidebar') < 0) {
        return
      }

      const scrollTarget = document.getElementsByClassName('product-list--wrapper')[0]

      if (scrollTarget) {
        setTimeout(() => {
          window.scrollTo({
            behavior: 'smooth',
            top: scrollTarget.offsetTop,
          })
        }, 100)
      }
    }

    handleScrollAfterFilterCategory()
  }, [])

  useEffect(() => {
    let mounted = true
    const { categoryKey } = category.projectSpecificData || {}

    if (categoryKey) {
      BlogService.getBlogTitle(categoryKey).then((res) => {
        if (res.data?.marketingTiles && mounted) {
          setBlogTiles(res.data.marketingTiles)
        }
      })
    }

    const handlePopstate = () => {
      const updatedSearchState = RoutingService.urlToSearchState(window.location, initQuery)
      onSearchStateChange(updatedSearchState, false)
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
      mounted = false
    }
  }, [])

  useEffect(() => {
    if (categoryUrl || initQuery) {
      onSearchStateChange({
        ...searchState,
      })
    }
  }, [categoryUrl, initQuery])

  useEffect(() => {
    if (activeFilter && count < 1) {
      setCount(1)
    }
  }, [activeFilter])

  useEffect(() => {
    let categoryName = categoryPageHeadline
    let tempCategorySeoTitle = categorySeoTitle
    let tempCategorySeoDescription = brandCategorySeo?.metaDescription || categorySeoDescription

    if (brandCategorySeo?.metaTitle) {
      tempCategorySeoTitle = brandCategorySeo.metaTitle
    } else if (searchState.refinementList?.brand?.length === 1) {
      const firstBrandInFilter = searchState.refinementList?.brand?.[0]
      setRefinementListBrand(searchState.refinementList?.brand)
      categoryName = ProductListService.getTitleWhenFilterByBrand(categoryName, firstBrandInFilter)
    } else {
      setRefinementListBrand([])
    }

    dispatcher(setNameCategory({
      nameCategory: categoryName
    }))

    dispatcher(setCategorySeo({
      categorySeoTitle: tempCategorySeoTitle,
      categorySeoDescription: tempCategorySeoDescription,
    }))
  }, [categoryPageHeadline, categorySeoDescription, categorySeoTitle, brandCategorySeo?.metaDescription, brandCategorySeo?.metaTitle, searchState])

  if (!data || !context) {
    return null
  }

  return (
    <div className={'product-list--wrapper'}>
      <Breadcrumb data={data} node={node} />
      <div className={'product-list--component'} data-insights-index={indexName}>
        <InstantSearch
          searchClient={algoliaClient}
          indexName={indexName}
          onSearchStateChange={onSearchStateChange}
          searchState={searchState}
          resultsState={resultsState}
        >
          <Configure
            {...searchConfigurations}
            query={searchState.query}
            userToken={context.session.account.accountId}
          />

          {isDisplayFilter && (
            <DesktopFilter
              category={category}
              context={context}
              headline={data?.headline}
              categoryPageHeadline={categoryPageHeadline}
              node={node}
              backgroundColor={data?.backgroundColor}
              algoliaIndexName={algoliaIndexName}
              refinementListBrand={refinementListBrand}
            />
          )}

          <EmptyResult searchFAQ={data?.searchFAQ} context={context} node={node} />

          <div className={'flex-col lg:flex-row lg:mt-8 lg:flex'}>
            {isDisplaySidebar && !isShowSlider && (
              <SidebarMenu
                items={sidebarCategories || []}
                facetCategory={facetCategory}
                nameCategory={category.name}
                currentCategory={category}
                node={node}
                displaySidebarByResult={displaySidebarByResult}
                selectedCategorySidebar={selectedCategorySidebar}
              />
            )}
            {isDisplaySidebar && isShowSlider && (
              <SlideMenu
                facetCategory={facetCategory}
                currentCategory={category}
                node={node}
                categoryTree={sidebarCategories || []}
                displaySidebarByResult={displaySidebarByResult}
              />
            )}

            <div className={'flex-grow'}>
              <div className={'sort-algolia'}>
                <CountProduct searchResults />
                <Sort
                  defaultRefinement={indexName}
                  isDisplaySorting={isDisplaySorting}
                  items={sortOptions}
                />
              </div>

              <Hits
                gtmCategoryName={gtmCategoryName}
                gtmCategoryId={gtmCategoryId}
                context={context}
                blogTiles={blogTiles}
                wishlistState={wishlistState}
                hasSidebarCategory={hasSidebarCategory}
                isDisplaySidebar={isDisplaySidebar}
                refererStore={data?.refererStore || refererStoreFromConfig}
              />
              {renderSeoBlock()}
            </div>
          </div>
        </InstantSearch>
      </div>
      <Head>
        {brandCategorySeo?.metaRobot && (
          <meta name='robots' content={StringHelpers.parseRobotsMeta(brandCategorySeo.metaRobot)} />
        )}
      </Head>
    </div>
  )
}

AlgoliaProductListTastic.propTypes = {
  node: PropTypes.objectOf(PropTypes.any).isRequired,
  tastic: PropTypes.objectOf(PropTypes.any).isRequired,
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  context: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  query: PropTypes.any,
  searhFAQ: PropTypes.any,
}

export default tastify({
  translate: true,
  connect: {
    node: true, tastic: true, route: true, context: true, wishlist: true,
  },
})(AlgoliaProductListTastic)
